@mixin ca-material-system-variables($theme) {
    --ca-material-background: #{mat.get-theme-color($theme, primary, 95)};
    // --ca-material-on-background:

    --ca-material-primary: #{mat.get-theme-color($theme, primary, 10)};
    --ca-material-on-primary: #{mat.get-theme-color($theme, primary, 100)};
    // --ca-material-primary-container:
    // --ca-material-on-primary-container:

    // --ca-material-secondary:
    // --ca-material-on-secondary:
    // --ca-material-secondary-container:
    // --ca-material-on-secondary-container:

    --ca-material-tertiary: #{mat.get-theme-color($theme, primary, 10)};
    --ca-material-on-tertiary: #{mat.get-theme-color($theme, primary, 100)};

    // --ca-material-tertiary-container
    // --ca-material-on-tertiary-container

    --ca-material-surface: #{mat.get-theme-color($theme, primary, 100)};
    --ca-material-on-surface: #{mat.get-theme-color($theme, primary, 10)};
    --ca-material-surface-container: #{mat.get-theme-color($theme, primary, 100)};

    --ca-material-surface-container-high: #{mat.get-theme-color($theme, primary, 100)};

    --ca-material-surface-variant: #{mat.get-theme-color($theme, primary, 10)};

    --ca-material-inverse-surface: #{mat.get-theme-color($theme, primary, 40)};
    --ca-material-inverse-on-surface: #{mat.get-theme-color($theme, primary, 100)};

    // There are more material system variables. The are just fundamental ones.
    // They are not described in the angular material documentation.
}
