@use '@angular/material' as mat;

@mixin ca-dialog-theme($theme) {
    .ca-dialog {
        --mdc-dialog-container-shape: 4px;
        --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
            0px 9px 46px 8px rgba(0, 0, 0, 0.12);
        --mdc-dialog-supporting-text-size: var(--ca-material-body-large-size);
        .mat-mdc-dialog-container .mdc-dialog__content {
            color: map-get($grey-palette, 10) !important;
        }
    }
}
