@use '@angular/material' as mat;

@mixin ca-menu-theme($theme) {
    .ca-menu {
        min-width: 185px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 8px;
        padding: 8px 0;
        border-radius: 8px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 16px 32px 0 rgba(0, 0, 0, 0.08);
        background-color: mat.get-theme-color($theme, primary, 100);

        .ca-menu-item {
            cursor: pointer;
            padding: 0 16px;
            height: 24px;
            font-size: 16px;
            font-family: inherit;
            line-height: 1.5;
            background-color: transparent;
            border-bottom: none;
            border-top: none;
            border-left: none;
            border-right: none;

            &:focus-visible {
                outline: none;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }

            &.disabled {
                color: mat.get-theme-color($theme, primary, 70);
                pointer-events: none;
                cursor: default;
            }
        }
    }
}
