@import '../palette';

@mixin ca-snackbar-theme($theme) {
    .ca-snackbar {
        --mdc-snackbar-supporting-text-size: 1rem;
        --mdc-text-button-label-text-size: 1rem;
        margin-bottom: 5vh !important;

        &.ca-info {
            .mdc-snackbar__surface {
                background-color: map-get($grey-palette, 40) !important;
            }

            .mdc-snackbar__label {
                color: map-get($grey-palette, 100) !important;
            }

            .mat-mdc-snack-bar-action {
                color: map-get($grey-palette, 100) !important;
            }

            .mat-mdc-button {
                color: map-get($grey-palette, 100) !important;
            }
        }
    }
}
