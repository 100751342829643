@mixin two-columns($column-gap: 8px, $row-gap: 16px) {
    box-sizing: border-box;
    margin-bottom: $row-gap;
    &:last-of-type {
        margin-bottom: 0;
    }
    @media screen and (min-width: $sm) {
        width: 50%;
        &:nth-of-type(odd) {
            padding-right: $column-gap;
        }
        &:nth-of-type(even) {
            padding-left: $column-gap;
        }

        &:nth-last-of-type(2) {
            margin-bottom: 0;
        }
    }
}

@mixin border-dark() {
    border: 1px solid map-get($grey-palette, 10);
    outline: 1px solid map-get($grey-palette, 10);
}

@mixin scrollbar($border-radius, $width: auto, $height: auto) {
    &::-webkit-scrollbar {
        width: $width !important;
        height: $height !important;
    }

    &::-webkit-scrollbar-track {
        border-radius: $border-radius !important;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(map-get($grey-palette, 10), 0.3);
        height: $height !important;
        width: $width !important;
        border-radius: $border-radius !important;
    }
}

@mixin scrollbar-vertical {
    @include scrollbar(6px, 6px);
}

@mixin scrollbar-horizontal {
    @include scrollbar(6px, auto, 6px);
}

@mixin tile {
    background-color: map-get($grey-palette, 100);
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    border: solid 1px map-get($grey-palette, 70);
}

@mixin tile-checked {
    border: solid 1px map-get($grey-palette, 10);
    outline: solid 1px map-get($grey-palette, 10);
}

@mixin tile-image-container {
    background-color: map-get($grey-palette, 95);
    display: flex;
    justify-content: center;
    padding: 7.5px 0;
}
