@use '@angular/material' as mat;

@mixin ca-datepicker-theme($theme) {
    --mat-datepicker-calendar-container-shape: 4px;
    --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    --mat-datepicker-calendar-date-disabled-state-text-color: #{mat.get-theme-color($theme, primary, 60)};
    .ca-datepicker-panel-class {
        &.mat-calendar {
            width: 384px !important;
        }

        .mat-calendar-body-label {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            line-height: 0;
            visibility: hidden;
        }
        .mat-calendar-table-header-divider {
            visibility: hidden;
        }

        // default cell style
        .mat-calendar-body-cell:hover:not(.mat-calendar-body-disabled):not(.mat-calendar-body-active) {
            .mat-calendar-body-cell-content {
                background-color: mat.get-theme-color($theme, primary, 95) !important;
            }
        }
        .mat-calendar-body-cell-content {
            font-size: 16px;
            width: 100%;
            height: 100%;
            background-color: mat.get-theme-color($theme, primary, 100) !important;
        }
        // selected cell style
        .mat-calendar-body-active {
            .mat-calendar-body-selected {
                background-color: mat.get-theme-color($theme, primary, 10) !important;
            }
            &:hover {
                .mat-calendar-body-selected {
                    background-color: mat.get-theme-color($theme, primary, 50) !important;
                }
            }
        }
        // today cell style
        .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
            border-width: 2px;
            border-color: mat.get-theme-color($theme, primary, 10);
        }
    }

    .mat-datepicker-content {
        position: absolute;
        top: 4px;
    }
}
