.ca-tile-tooltip {
    position: absolute;
    top: 8px;
    right: 8px;
}

.ca-pre-line {
    white-space: pre-line;
}

/**
    Move it to new shared components library once styles created.
*/
.ca-badge {
    padding: 4px 8px;
    background-color: var(--ca-grey-90);
    border-radius: 2px;
    font-weight: 600;
}
