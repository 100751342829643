// Breakpoints variables
$xs: 316px;
$sm: 599px;
$md: 1025px;
$lg: 1440px;
$xl: 1920px;

// Override bootstrap grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: $sm,
    md: $md,
    lg: $lg,
    xl: $xl
);

$container-max-widths: (
    xs: $xs,
    sm: $sm,
    md: $md,
    lg: $lg
);
