@use '@angular/material' as mat;
@import '../variables';

@mixin ca-button-basic() {
    box-shadow: none;
    border-radius: 50px !important;
    letter-spacing: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 1.29 !important;
    padding: 11px 28px;
    height: 40px;
    transition: background-color $transition-150, border-color $transition-150;

    &-xs {
        font-size: 14px !important;
        padding: 7px 19px;
        height: 32px;
        min-width: 48px !important;
    }

    &-s {
        font-size: 14px !important;
        padding: 11px 28px;
        height: 40px;
    }

    &-m {
        padding: 15px 36px;
        font-size: 16px !important;
        line-height: 1.13 !important;
        height: 48px;
    }

    &-l {
        padding: 19px 36px;
        font-size: 16px !important;
        line-height: 1.13 !important;
        height: 56px;
    }

    &-no-padding {
        padding: 0;
        height: auto;
    }

    $space: 8px;

    ca-icon + span {
        margin-left: $space;
    }

    span + ca-icon {
        margin-left: $space;
    }

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 $space;

        span {
            display: block;
        }

        > mat-icon {
            margin-right: 0.5rem;
        }
    }
}

@mixin ca-button-link() {
    border: none !important;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 1.29 !important;
    padding: 10px 12px;
    letter-spacing: normal;

    $space: 8px;

    ca-icon + span {
        margin-left: $space;
    }

    span + ca-icon {
        margin-left: $space;
    }

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            display: block;
        }
    }
}

@function get-dark-theme-config($theme) {
    @return (
        // STANDARD BUTTON STYLES
        button-hover-color-hue: mat.get-theme-color($theme, secondary, 50),
        button-disabled-color-hue: mat.get-theme-color($theme, primary, 50),
        // FLAT BUTTON STYLES
        flat-button-border-hue: null,
        flat-button-color-hue: null,
        flat-button-background-hue: null,
        // :hover
        flat-button-hover-border-hue: null,
        flat-button-hover-color-hue: null,
        flat-button-hover-background-hue: mat.get-theme-color($theme, secondary, 50),
        // :focus
        flat-button-focus-border-hue: null,
        flat-button-focus-color-hue: null,
        flat-button-focus-background-hue: mat.get-theme-color($theme, primary, 40),
        // :disabled
        flat-button-disabled-background-hue: mat.get-theme-color($theme, primary, 90),
        flat-button-disabled-color-hue: mat.get-theme-color($theme, primary, 60),
        // STROKED BUTTON STYLES
        stroked-button-border-hue: mat.get-theme-color($theme, primary, 70),
        stroked-button-color-hue: null,
        stroked-button-background-hue: mat.get-theme-color($theme, primary, 70),
        // :hover
        stroked-button-hover-border-hue: mat.get-theme-color($theme, primary, 60),
        stroked-button-hover-color-hue: mat.get-theme-color($theme, primary, 50),
        stroked-button-hover-background-hue: mat.get-theme-color($theme, primary, 95),
        // :focus
        stroked-button-focus-border-hue: mat.get-theme-color($theme, primary, 50),
        stroked-button-focus-color-hue: null,
        stroked-button-focus-background-hue: mat.get-theme-color($theme, primary, 80),
        // :disabled
        stroked-button-disabled-border-hue: mat.get-theme-color($theme, primary, 70),
        stroked-button-disabled-color-hue: mat.get-theme-color($theme, primary, 70),
        // LINK BUTTON STYLES
        link-button-hover-color-hue: mat.get-theme-color($theme, primary, 50),
        link-button-focus-color-hue: mat.get-theme-color($theme, primary, 40),
        link-button-disabled-color-hue: mat.get-theme-color($theme, primary, 70)
    );
}

@function get-light-theme-config($theme) {
    @return (
        // STANDARD BUTTON STYLES
        button-hover-color-hue: mat.get-theme-color($theme, secondary, 50),
        button-disabled-color-hue: mat.get-theme-color($theme, primary, 50),
        // FLAT BUTTON STYLES
        flat-button-border-hue: mat.get-theme-color($theme, primary, 50),
        flat-button-color-hue: mat.get-theme-color($theme, primary, 100),
        flat-button-background-hue: mat.get-theme-color($theme, primary, 50),
        // :hover
        flat-button-hover-border-hue: mat.get-theme-color($theme, secondary, 40),
        flat-button-hover-color-hue: mat.get-theme-color($theme, primary, 95),
        flat-button-hover-background-hue: mat.get-theme-color($theme, primary, 40),
        // :focus
        flat-button-focus-border-hue: mat.get-theme-color($theme, primary, 30),
        flat-button-focus-color-hue: mat.get-theme-color($theme, primary, 100),
        flat-button-focus-background-hue: mat.get-theme-color($theme, primary, 30),
        // :disabled
        flat-button-disabled-background-hue: mat.get-theme-color($theme, primary, 98),
        flat-button-disabled-color-hue: mat.get-theme-color($theme, primary, 70),
        // STROKED BUTTON STYLES
        stroked-button-border-hue: mat.get-theme-color($theme, primary, 50),
        stroked-button-color-hue: mat.get-theme-color($theme, primary, 50),
        stroked-button-background-hue: null,
        // :hover
        stroked-button-hover-border-hue: mat.get-theme-color($theme, primary, 40),
        stroked-button-hover-color-hue: mat.get-theme-color($theme, primary, 40),
        stroked-button-hover-background-hue: mat.get-theme-color($theme, primary, 98),
        // :focus
        stroked-button-focus-border-hue: mat.get-theme-color($theme, primary, 30),
        stroked-button-focus-color-hue: mat.get-theme-color($theme, primary, 30),
        stroked-button-focus-background-hue: mat.get-theme-color($theme, primary, 95),
        // :disabled
        stroked-button-disabled-border-hue: mat.get-theme-color($theme, primary, 80),
        stroked-button-disabled-color-hue: mat.get-theme-color($theme, primary, 80),
        // LINK BUTTON STYLES
        link-button-hover-color-hue: mat.get-theme-color($theme, primary, 50),
        link-button-focus-color-hue: mat.get-theme-color($theme, primary, 40),
        link-button-disabled-color-hue: mat.get-theme-color($theme, primary, 70)
    );
}

@mixin ca-buttons-theme($theme) {
    $button-theme-config: get-dark-theme-config($theme);

    $theme-type: mat.get-theme-type($theme);
    @if ($theme-type == light) {
        $button-theme-config: get-light-theme-config($theme);
    }

    button[mat-flat-button],
    a[mat-flat-button] {
        &.ca-button {
            @include ca-button-basic();
            background-color: map-get($button-theme-config, flat-button-background-hue);
            color: map-get($button-theme-config, flat-button-color-hue);
            &:hover {
                background-color: map-get($button-theme-config, flat-button-hover-background-hue);
                border-color: map-get($button-theme-config, flat-button-hover-border-hue);
                color: map-get($button-theme-config, flat-button-hover-color-hue);
            }

            &:focus {
                background-color: map-get($button-theme-config, flat-button-focus-background-hue);
                border-color: map-get($button-theme-config, flat-button-focus-border-hue);
                color: map-get($button-theme-config, flat-button-focus-color-hue);
            }

            &:disabled {
                background-color: map-get($button-theme-config, flat-button-disabled-background-hue) !important;
                color: map-get($button-theme-config, flat-button-disabled-color-hue) !important;
            }
        }
    }

    button[mat-button],
    a[mat-button] {
        &.ca-button {
            @include ca-button-basic();

            &:hover {
                color: map-get($button-theme-config, button-hover-color-hue);
                --mat-text-button-state-layer-color: transparent !important;

                .mat-button-focus-overlay {
                    display: none;
                }
            }

            &:disabled {
                color: map-get($button-theme-config, button-disabled-color-hue);
            }
        }
    }

    button[mat-stroked-button],
    a[mat-stroked-button] {
        border-color: map-get($button-theme-config, stroked-button-border-hue);
        color: map-get($button-theme-config, stroked-button-color-hue);

        &.ca-button {
            @include ca-button-basic();

            &:hover {
                --mat-outlined-button-state-layer-color: transparent !important;
                border-color: map-get($button-theme-config, stroked-button-hover-border-hue);
                color: map-get($button-theme-config, stroked-button-hover-color-hue);
                background-color: map-get($button-theme-config, stroked-button-hover-background-hue);
                .mat-button-focus-overlay {
                    display: none;
                }
            }

            &:focus {
                border-color: map-get($button-theme-config, stroked-button-focus-border-hue);
                outline: 1px solid map-get($button-theme-config, stroked-button-focus-border-hue);
                color: map-get($button-theme-config, stroked-button-focus-color-hue);
                background-color: map-get($button-theme-config, stroked-button-focus-background-hue);
            }

            &:disabled {
                border-color: map-get($button-theme-config, stroked-button-disabled-border-hue);
                color: map-get($button-theme-config, stroked-button-disabled-color-hue);
            }
        }
    }

    button[mat-icon-button] {
        &.ca-button {
            display: flex;
            justify-content: center;
            align-items: center;

            .mat-mdc-button-persistent-ripple {
                display: none;
            }

            &-s {
                width: 25px;
                height: 25px;
                .mat-mdc-button-touch-target {
                    width: 25px;
                    height: 25px;
                }
            }

            .mat-button-wrapper {
                display: flex;
                justify-content: center;
            }

            .mat-button-focus-overlay {
                display: none;
            }
        }
    }

    button[mat-stroked-button],
    a[mat-button] {
        &.ca-button-link {
            @include ca-button-link();

            &:hover {
                --mat-outlined-button-state-layer-color: transparent !important;
                color: map-get($button-theme-config, link-button-hover-color-hue) !important;

                .mat-button-focus-overlay {
                    display: none;
                }

                span {
                    text-decoration: underline;
                }
            }

            &:focus {
                color: map-get($button-theme-config, link-button-focus-color-hue) !important;

                .mat-button-focus-overlay {
                    display: none;
                }
            }

            &:disabled {
                color: map-get($button-theme-config, link-button-disabled-color-hue) !important;

                .mat-button-wrapper {
                    span {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
