@mixin ca-card() {
    $borderColor: var(--ca-grey-70);

    --ca-card-border-radius: 8px;
    --ca-card-border: 1px solid #{$borderColor};

    .ca-card {
        box-sizing: border-box;
        border-radius: var(--ca-card-border-radius);
        border: var(--ca-card-border);
    }
}
