@mixin ca-select-theme($theme) {
    .mat-mdc-select-panel {
        @include scrollbar-vertical();

        .mat-pseudo-checkbox-minimal {
            display: none;
        }

        .mat-pseudo-checkbox {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            &::after {
                transform: scale(0.9) rotate(-45deg);
            }
        }
    }

    .ca-dropdown-panel-class,
    .ca-multiselect-dropdown-panel-class,
    .ca-search-dropdown-panel-class {
        position: absolute;
        top: 4px;

        .mat-active,
        .mat-option:hover:not(.mat-option-disabled),
        .mat-option:focus:not(.mat-option-disabled) {
            background: mat.get-theme-color($theme, primary, 80);
        }
    }

    .ca-multiselect-dropdown-panel-class {
        &.mat-primary {
            .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
                border-color: mat.get-theme-color($theme, primary, 10);
                background-color: transparent;
                &::after {
                    color: mat.get-theme-color($theme, primary, 10);
                }
            }
        }
    }

    .ca-search-dropdown-panel-class {
        &.mat-select-panel {
            max-height: 340px;
        }

        .mat-option {
            height: auto !important;
        }
    }

    .ca-zip-city-search-dropdown-panel-class {
        &.mat-select-panel {
            max-height: 336px;
        }
    }

    .ca-partner-id-search-dropdown-panel-class {
        &.mat-select-panel {
            max-height: 416px;
        }
    }

    .ca-text-field-dropdown-input {
        z-index: 1;
    }

    .ca-text-field-dropdown {
        .mat-select-value-text,
        .mat-select-arrow-wrapper {
            visibility: hidden;
        }
    }

    // Sets dropdown arrow to the vertical center
    .mat-form-field {
        &.ca-form-field-s.mat-form-field-appearance-outline {
            .mat-select-arrow-wrapper {
                transform: translateY(0);
            }
        }
    }

    .mat-select-value {
        color: mat.get-theme-color($theme, primary, 10);
    }

    .mat-select-placeholder {
        color: mat.get-theme-color($theme, primary, 50);
    }
}
