@use '@angular/material' as mat;
@import '../palette';
@import '../breakpoints';

@mixin responsive-cell() {
    flex: 1 1 0 !important;

    &:first-of-type {
        flex: 1.6 1 0 !important;
    }
    &:nth-of-type(2) {
        flex: 1.5 1 0 !important;
    }
    &:nth-of-type(3) {
        flex: 1.6 1 0 !important;
    }
    &:nth-of-type(4) {
        flex: 1.05 1 0 !important;
    }
    &:nth-of-type(6) {
        flex: 0.5 1 0 !important;
    }
    @media screen and (min-width: $md) {
        &:first-of-type {
            flex: 1.5 1 0 !important;
        }
        &:nth-of-type(2) {
            flex: 1 1 0 !important;
        }
        &:nth-of-type(3) {
            flex: 1.7 1 0 !important;
        }
        &:nth-of-type(4) {
            flex: 1.05 1 0 !important;
        }
        &:nth-of-type(6) {
            flex: 0.25 1 0 !important;
        }
    }
    @media screen and (min-width: $lg) {
        &:first-of-type {
            flex: 3.2 1 0 !important;
        }
        &:nth-of-type(2) {
            flex: 1.1 1 0 !important;
        }
        &:nth-of-type(3) {
            flex: 3.2 1 0 !important;
        }
        &:nth-of-type(4) {
            flex: 1.6 1 0 !important;
        }
        &:nth-of-type(6) {
            flex: 0.5 1 0 !important;
        }
    }
}

@mixin ca-table-theme($theme) {
    .ca-table {
        --mat-table-background-color: mat.get-theme-color($theme, primary, 100);
        .mat-mdc-table {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        .mat-mdc-header-row {
            height: 72px !important;
            border-bottom: 2px solid map-get($grey-palette, 95);
        }

        .mat-mdc-header-cell {
            color: map-get($grey-palette, 10) !important;
            padding-left: 16px;
            &.mdc-data-table__header-cell {
                padding-right: 0;
                &:first-of-type {
                    padding-left: 16px;
                }

                &:last-of-type {
                    padding-right: 16px;
                }
                @include responsive-cell();
            }
        }

        .mat-mdc-row {
            min-height: 64px !important;
            border-bottom: 2px solid map-get($grey-palette, 95);

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: map-get($grey-palette, 95);
            }

            @media screen and (min-width: $lg) {
                min-height: 80px !important;
            }

            &-non-clickable {
                &:hover {
                    background-color: unset;
                }
            }
        }

        .mat-mdc-cell {
            color: map-get($grey-palette, 10) !important;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: flex-start;

            &.mdc-data-table__cell {
                padding: 8px 0 8px 16px;

                &:last-of-type {
                    padding: 0 16px;
                    line-height: 56px;
                    text-align: center;

                    @media screen and (min-width: $lg) {
                        line-height: 72px;
                    }
                }
            }
            @include responsive-cell();
        }
    }
}

.cell-wrapper {
    max-height: 63px;

    &.cell-wrapper-ellipsis {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    @media screen and (min-width: $md) {
        max-height: 72px;
    }
}
