@use '@angular/material' as mat;
@import '../variables';

@mixin ca-chips-theme($theme) {
    .ca-filter-chip {
        .mat-mdc-chip-focus-overlay {
            display: none;
        }
        &.mat-mdc-chip.mat-mdc-standard-chip {
            background: transparent;
            border: 1px solid mat.get-theme-color($theme, primary, 70);

            &:hover {
                background: transparent;
                border-color: mat.get-theme-color($theme, primary, 60);
            }

            &:active {
                box-shadow: none;
            }

            &::after {
                display: none;
            }

            .mat-chip-remove,
            .mat-chip-trailing-icon {
                width: 16px;
                height: 16px;
            }
            .mat-icon {
                width: inherit;
                height: inherit;
                font-size: inherit;
            }
        }
    }
}
