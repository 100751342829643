@mixin ca-icons-theme($theme) {
    $primary: map-get($theme, primary);

    @font-face {
        font-family: 'ca-icons';
        src: url('/assets/shared/fonts/ca-icons/ca-icons.ttf?n6ndy') format('truetype'),
            url('/assets/shared/fonts/ca-icons/ca-icons.woff?n6ndy') format('woff'),
            url('/assets/shared/fonts/ca-icons/ca-icons.svg?n6ndy#ca-icons') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    .ca.mat-icon {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'ca-icons' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1 !important;
        width: auto;
        height: auto;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &.order-asc .path1:before {
            content: '\e92e';
            color: rgb(255, 62, 23);
        }
        &.order-asc .path2:before {
            content: '\e92f';
            margin-left: -0.5712890625em;
            color: rgb(133, 133, 133);
        }
        &.order-desc .path1:before {
            content: '\e930';
            color: rgb(133, 133, 133);
        }
        &.order-desc .path2:before {
            content: '\e931';
            margin-left: -0.5712890625em;
            color: rgb(255, 62, 23);
        }
        &.order:before {
            content: '\e932';
            color: #858585;
        }
        &.copy:before {
            content: '\e92c';
        }
        &.cross-circle:before {
            content: '\e904';
        }
        &.check:before {
            content: '\e910';
        }
        &.big-plus:before {
            content: '\e929';
        }
        &.file-download:before {
            content: '\e92a';
        }
        &.exclamation:before {
            content: '\e92b';
        }
        &.update:before {
            content: '\e927';
        }
        &.more-vertical:before {
            content: '\e928';
        }
        &.apartment-house:before {
            content: '\e900';
        }
        &.new-building:before {
            content: '\e901';
        }
        &.old-building:before {
            content: '\e902';
        }
        &.single-family-house:before {
            content: '\e903';
        }
        &.magnifier:before {
            content: '\e905';
        }
        &.plus:before {
            content: '\e906';
        }
        &.settings:before {
            content: '\e907';
        }
        &.arrow-left:before {
            content: '\e908';
        }
        &.ventilation:before {
            content: '\e909';
        }
        &.solar:before {
            content: '\e90a';
        }
        &.pencil:before {
            content: '\e90b';
        }
        &.heating:before {
            content: '\e90c';
        }
        &.battery-full:before {
            content: '\e90d';
        }
        &.arrow-right:before {
            content: '\e90e';
        }
        &.arrow-outward:before {
            content: '\e90f';
        }
        &.check-default:before {
            content: '\e92d';
        }
        &.info:before {
            content: '\e911';
        }
        &.heat-pump:before {
            content: '\e912';
        }
        &.calculator:before {
            content: '\e913';
        }
        &.house-default:before {
            content: '\e914';
        }
        &.cross:before {
            content: '\e915';
        }
        &.oil-boiler:before {
            content: '\e916';
        }
        &.gas-boiler:before {
            content: '\e917';
        }
        &.electric-heater:before {
            content: '\e918';
        }
        &.wood-stove:before {
            content: '\e919';
        }
        &.floor-heating:before {
            content: '\e91a';
        }
        &.radiator-heating:before {
            content: '\e91b';
        }
        &.radiator-and-underfloor:before {
            content: '\e91c';
        }
        &.wood:before {
            content: '\e920';
        }
        &.pellet:before {
            content: '\e921';
        }
        &.heatpump-outline:before {
            content: '\e91d';
        }
        &.quote:before {
            content: '\e91e';
        }
        &.trash:before {
            content: '\e91f';
        }
        &.warning:before {
            content: '\e922';
        }
        &.assignment:before {
            content: '\e923';
        }
        &.district-heating:before {
            content: '\e924';
        }
        &.solar-thermal:before {
            content: '\e925';
        }
        &.arrow-bounded:before {
            content: '\e926';
        }
        &.calendar:before {
            content: '\e933';
        }
        &.chevron-left:before {
            content: '\e934';
        }
        &.chevron-right:before {
            content: '\e935';
        }
        &.double-chevron-left:before {
            content: '\e936';
        }
        &.double-chevron-right:before {
            content: '\e937';
        }
        &.paragraph:before {
            content: '\e938';
        }
        &.info-outline:before {
            content: '\e939';
        }
        &.cart:before {
            content: '\e93a';
        }
        &.trash-outline:before {
            content: '\e93b';
        }
        &.link:before {
            content: '\e93c';
        }
    }
}
