// Deprecated palette, Kept because it holds #ff3e17 brand color.
$red-palette: (
    0: #000000,
    10: #a8331c,
    20: #6f1300,
    25: #6f1300,
    30: #a71c00,
    35: #a71c00,
    40: #de2500,
    50: #ff3e17,
    60: #ff6545,
    70: #ff8b74,
    80: #ffb2a2,
    90: #ffd8d1,
    95: #ffece8,
    98: #fff5f3,
    99: #fffaf9,
    100: #ffffff
);

// In future it will replace the red or salmon palette.
$red-moment-palette: (
    0: #000000,
    10: #290c06,
    20: #441f17,
    25: #441f17,
    30: #64281c,
    35: #64281c,
    40: #902a17,
    50: #cc2c08,
    60: #ff3e17,
    70: #fe876f,
    80: #fcb2a2,
    90: #ffd6cd,
    95: #ffe4df,
    98: #fff7f5,
    99: #fff7f5,
    100: #ffffff
);

// Relates to red palette in Zeplin
$salmon-palette: (
    0: #000000,
    10: #6b0915,
    20: #810f15,
    25: #810f15,
    30: #a11c19,
    35: #a11c19,
    40: #c03424,
    50: #ec8562,
    60: #f5a883,
    70: #fbcdad,
    80: #fde9d6,
    90: #fff3e5,
    95: #fff3e5,
    98: #fff3e5,
    99: #fff3e5,
    100: #ffffff
);

$grey-palette: (
    0: #000000,
    10: #111111,
    20: #1f1f1f,
    25: #1f1f1f,
    30: #242424,
    35: #242424,
    40: #3f3f3f,
    50: #545454,
    60: #858585,
    70: #d0d0d0,
    80: #dedede,
    90: #f0f0f0,
    95: #f6f6f6,
    98: #f6f6f6,
    99: #fafafa,
    100: #ffffff
);

$green-palette: (
    0: #000000,
    10: #0d3c17,
    20: #166427,
    25: #166427,
    30: #098624,
    35: #098624,
    40: #23a03e,
    50: #2cc84d,
    60: #56d371,
    70: #96e4a6,
    80: #abe9b8,
    90: #abe9b8,
    95: #d5f4db,
    98: #eafaed,
    99: #eafaed,
    100: #ffffff
);

$orange-palette: (
    0: #000000,
    10: #774200,
    20: #905500,
    25: #905500,
    30: #b36f01,
    35: #b36f01,
    40: #d68c01,
    50: #fbc640,
    60: #fdd766,
    70: #fee899,
    80: #fef5cc,
    90: #fffae1,
    95: #fffae1,
    98: #fffae1,
    99: #fffae1,
    100: #ffffff
);

$blue-palette: (
    0: #000000,
    10: #072233,
    20: #0f4466,
    25: #0f4466,
    30: #166799,
    35: #166799,
    40: #1b78b1,
    50: #1e89cc,
    60: #25aaff,
    70: #51bcff,
    80: #7ccdff,
    90: #a8ddff,
    95: #d3eeff,
    98: #d3eeff,
    99: #d3eeff,
    100: #ffffff
);

$seledin-palette: (
    0: #000000,
    10: #3c6163,
    20: #55898d,
    25: #55898d,
    30: #68a8ac,
    35: #68a8ac,
    40: #78c2c7,
    50: #87dadf,
    60: #a6e1e5,
    70: #c0e9ec,
    80: #d7f0f2,
    90: #ebf8f8,
    95: #f5fbfb,
    98: #f5fbfb,
    99: #f5fbfb,
    100: #ffffff
);

$turquoise-palette: (
    0: #000000,
    10: #092a31,
    20: #134c61,
    30: #1c7e92,
    35: #1c7e92,
    40: #3c90a1,
    50: #5da3b1,
    60: #7db5c1,
    70: #9ec8d0,
    80: #bedae0,
    90: #dfedef,
    95: #eff6f7,
    98: #eff6f7,
    99: #eff6f7,
    100: #ffffff
);

$purple-palette: (
    0: #000000,
    10: #4a193b,
    20: #682453,
    25: #682453,
    30: #802c66,
    35: #802c66,
    40: #943376,
    50: #a63a84,
    60: #bb7da4,
    70: #cea7be,
    80: #dfc8d6,
    90: #efe5eb,
    95: #f7f2f5,
    98: #f7f2f5,
    99: #f7f2f5,
    100: #ffffff
);

$red-m3-palette-base: (
    secondary: $grey-palette,
    error: $salmon-palette,
    neutral: $grey-palette,
    neutral-variant: $grey-palette
);
$red-m3-palette: map-merge($red-m3-palette-base, $red-palette);

$red-moment-m3-palette: map-merge($red-m3-palette-base, $red-moment-palette);

$grey-m3-palette-base: (
    secondary: $grey-palette,
    error: $salmon-palette,
    neutral: $grey-palette,
    neutral-variant: $grey-palette
);
$grey-m3-palette: map-merge($grey-m3-palette-base, $grey-palette);

/**
 * TODO: Move it to new shared ca-ui library once it is created.
 */
@mixin ca-colors() {
    --ca-grey-0: #000000;
    --ca-grey-10: #111111;
    --ca-grey-20: #1f1f1f;
    --ca-grey-25: #1f1f1f;
    --ca-grey-30: #242424;
    --ca-grey-35: #242424;
    --ca-grey-40: #3f3f3f;
    --ca-grey-50: #545454;
    --ca-grey-60: #858585;
    --ca-grey-70: #d0d0d0;
    --ca-grey-80: #dedede;
    --ca-grey-90: #f0f0f0;
    --ca-grey-95: #f6f6f6;
    --ca-grey-98: #f6f6f6;
    --ca-grey-99: #fafafa;
    --ca-grey-100: #ffffff;
}
