@mixin ca-carousel-theme($theme) {
    .ca-carousel {
        .owl-theme {
            .owl-nav.disabled + .owl-dots {
                margin-top: 20px;
            }
            .owl-dots {
                .owl-dot {
                    span {
                        background: mat.get-theme-color($theme, primary, 70);
                        width: 24px;
                        height: 4px;
                        margin: 5px 4px;
                        border-radius: 0;
                    }
                    &.active span {
                        background: mat.get-theme-color($theme, primary, 50);
                    }
                }
            }
        }
        .owl-stage {
            display: flex;
            justify-content: stretch;
            &::after {
                content: '';
            }
        }
    }
}
