@use '@angular/material' as mat;
@import '../variables';

@mixin ca-tab-theme($theme) {
    .mat-mdc-tab-label-container {
        border-bottom-color: mat.get-theme-color($theme, tertiary, 80);
    }

    .mat-mdc-tab-list {
        flex-grow: initial;
    }

    .mat-mdc-tab {
        .mdc-tab__text-label {
            color: mat.get-theme-color($theme, tertiary, 10);
            font-size: 16px;
            font-weight: 600;
            line-height: 1.38;
        }

        .mdc-tab-indicator__content--underline {
            border-color: mat.get-theme-color($theme, primary, 50);
        }

        &.mdc-tab--active {
            .mdc-tab__text-label {
                color: mat.get-theme-color($theme, tertiary, 10);
            }

            &:hover,
            &:focus {
                .mdc-tab__text-label {
                    color: mat.get-theme-color($theme, tertiary, 20);
                }

                .mdc-tab-indicator__content--underline {
                    border-color: mat.get-theme-color($theme, primary, 50);
                }
            }
        }

        &:hover {
            .mdc-tab__text-label {
                color: mat.get-theme-color($theme, tertiary, 40);
            }
        }
    }
}
