@use '@angular/material' as mat;
@import '../variables';
@import '../breakpoints';
@import '../mixins';

@mixin ca-radio-error($color) {
    border-color: $color;
    transition: border-color 0ms;
    outline: $color solid 1px;
    outline-offset: -2px;
}

@mixin ca-radio-disabled($color) {
    &.ca-radio-disabled {
        .mat-internal-form-field:hover {
            border-color: $color, 300;
        }

        .mdc-radio__outer-circle {
            background-color: $color, 50;
        }
    }
}

@mixin ca-radio-theme($theme) {
    mat-radio-group.ca-radio-group {
        --mat-radio-label-text-line-height: 1.5rem;
        --mat-radio-label-text-size: 1rem;
        .mat-mdc-radio-button {
            cursor: pointer;
        }

        .mat-internal-form-field {
            border: 1px solid mat.get-theme-color($theme, primary, 70);
            align-items: flex-start !important;
            width: 100%;
            box-sizing: border-box;
            border-radius: $border-radius-6;
            transition: border-color $transition-150;
            .mdc-radio__background::before {
                display: none;
            }
            .mdc-radio {
                padding: 13px 10px 13px 18px;
            }

            &:hover {
                // TODO: check it
                border-color: mat.get-theme-color($theme, secondary, 50);
            }
        }
        .mat-mdc-radio-checked {
            .mat-internal-form-field {
                border-color: mat.get-theme-color($theme, primary, 50) !important;
            }
        }

        &.ng-touched.ng-invalid {
            .mat-internal-form-field {
                @include ca-radio-error(mat.get-theme-color($theme, error, 40));
            }
        }

        .mdc-form-field {
            label {
                padding: 11px 36px 11px 0;
                cursor: pointer;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                // 48px is radio's circle width
                width: calc(100% - 48px);
            }
        }

        &.ca-radio-no-circle {
            .mdc-radio {
                display: none;
            }
            .mdc-form-field {
                label {
                    padding: 14px 18px;
                    align-items: center;
                    justify-content: center;
                }
            }
            ca-icon {
                order: 0;
                color: mat.get-theme-color($theme, primary, 70);
            }
            .mat-mdc-radio-button {
                // TODO: check it
                color: mat.get-theme-color($theme, secondary, 50);
                transition: color $transition-150;
                &:hover {
                    ca-icon {
                        // TODO: check it
                        color: mat.get-theme-color($theme, secondary, 50);
                    }
                }
                &.mat-mdc-radio-checked {
                    color: mat.get-theme-color($theme, primary, 50);
                    ca-icon {
                        color: mat.get-theme-color($theme, primary, 50);
                    }
                }
            }
        }
        &.ca-radio-thick {
            .mat-internal-form-field {
                display: block;
                border-width: 2px;
                ca-icon {
                    order: 0;
                    margin-right: 8px;
                }
            }
        }
        .ca-radio-with-image {
            .mdc-form-field {
                label {
                    flex-direction: column;
                    padding: 14px 18px;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .ca-radio-button-no-content {
            .mdc-form-field {
                label {
                    display: none;
                }
            }
        }

        .ca-radio-tile-with-visual {
            @include tile();
            .mdc-form-field {
                position: relative;
                display: grid;
                grid-template:
                    'visual visual'
                    'circle text'
                    / auto 1fr;
                label {
                    display: contents;
                }
            }
            .ca-radio-visual-container {
                @include tile-image-container();
                justify-self: center;
                width: 100%;
                grid-area: visual;
                img {
                    height: 100px;
                }
            }
            .mdc-radio {
                padding: 10px;
                grid-area: circle;
            }
            .ca-radio-label-text {
                grid-area: text;
                justify-self: start;
                align-self: center;
            }
            &.mat-mdc-radio-checked {
                @include tile-checked();
            }
        }

        .ca-radio-tile-with-tooltip,
        .ca-radio-with-icon {
            .mdc-form-field {
                label {
                    justify-content: space-between;
                    flex-direction: row;
                    padding-right: 18px;
                    ca-icon {
                        order: 1;
                    }
                }
            }
        }
        .ca-radio-tile-with-tooltip {
            .mdc-form-field {
                label {
                    justify-content: flex-start;
                }
            }
        }
        .ca-variant-modal-radio {
            .mat-internal-form-field {
                .mdc-radio {
                    padding: 6px 10px 6px 0px;
                    input {
                        height: 20px;
                        width: 20px;
                        top: 6px;
                    }
                    .mat-mdc-radio-touch-target {
                        height: 20px;
                        width: 20px;
                        left: 10px;
                    }
                }
            }
            .mdc-form-field {
                label {
                    padding: 4px 36px 4px 0;
                }
            }
        }
    }

    .ca-custom-answer-radio {
        mat-radio-group {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            @media screen and (min-width: $md) {
                flex-direction: row;
            }
            .ca-custom-radio-error {
                .mat-internal-form-field {
                    @include ca-radio-error(mat.get-theme-color($theme, error, 40));
                }
            }
            @include ca-radio-disabled(mat.get-theme-color($theme, primary, 50));
        }

        mat-radio-button {
            box-sizing: border-box;
            margin-bottom: 16px;

            @media screen and (min-width: $md) {
                width: 50%;
                &:first-of-type,
                &:nth-of-type(3) {
                    padding-right: $column-gap;
                }
                &:nth-of-type(2),
                &:nth-of-type(4) {
                    padding-left: $column-gap;
                }
                &:nth-of-type(3) {
                    margin-bottom: 0;
                }
            }
            @include ca-radio-disabled(mat.get-theme-color($theme, primary, 50));
        }
        mat-form-field {
            width: 100%;
            .mat-mdc-form-field-flex {
                width: 100%;
            }
            @media screen and (min-width: $md) {
                box-sizing: border-box;
                width: 50%;
                padding-right: $column-gap;
            }
        }
    }
}

.ca-radio-columns {
    display: flex;
    flex-direction: column;
    @media (min-width: $sm) {
        display: inline;
    }
    .mat-mdc-radio-button {
        @include two-columns();
        .mat-internal-form-field {
            width: 100%;
        }
    }
}

.ca-radio-single-column {
    display: flex;
    flex-direction: column;
    .mat-mdc-radio-button {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
        @media screen and (min-width: $sm) {
            width: calc(50% - $column-gap);
        }
    }
}

.ca-radio-columns-narrow-gap {
    .mat-mdc-radio-button {
        @include two-columns(4px);
        .mat-internal-form-field {
            width: 100%;
        }
    }
}

.ca-radio-margin {
    .mat-mdc-radio-button {
        margin-bottom: 16px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.ca-radio-width-260 {
    .mat-mdc-radio-button {
        width: auto;
        @media screen and (min-width: $sm) {
            width: 260px;
        }
    }
}

.ca-radio-width-400 {
    .mat-mdc-radio-button {
        width: auto;
        @media screen and (min-width: $sm) {
            width: 400px;
        }
    }
}

.ca-radio-width-full {
    .mat-mdc-radio-button {
        width: 100%;
        @media screen and (min-width: $sm) {
            width: 100%;
        }
    }
}

.ca-no-border-radio {
    .mat-internal-form-field {
        padding: 0;
        border: 0 none !important;
    }
}
