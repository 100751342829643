@import '../mixins';
@import '../palette';

@mixin ca-checkbox-theme($theme) {
    .ca-checkbox {
        --mat-checkbox-label-text-size: 1rem;
        --mat-checkbox-label-text-line-height: 0;
        margin-left: -10px;
        .mdc-checkbox__ripple {
            display: none;
        }
        .mdc-checkbox__background {
            width: 20px !important;
            height: 20px !important;
            border-radius: 4px;
        }
        svg.mdc-checkbox__checkmark {
            transform: scale(0.7);
        }
        .mdc-checkbox__background {
            background-color: transparent !important;
        }
        svg.mdc-checkbox__checkmark {
            color: map-get($grey-palette, 10) !important;
        }
        .mdc-checkbox__mixedmark {
            margin: 0 2px;
            border-color: unset !important;
        }
        &.mat-mdc-checkbox-disabled {
            .mdc-checkbox__background {
                background-color: mat.get-theme-color($theme, primary, 95) !important;
            }
            svg.mdc-checkbox__checkmark {
                color: mat.get-theme-color($theme, primary, 70) !important;
            }
        }
    }

    .ca-checkbox-tile-with-visual {
        .mat-mdc-checkbox {
            position: relative;
            @include tile();
            &.mat-mdc-checkbox-checked {
                @include tile-checked();
            }
        }
        .mdc-form-field {
            display: grid;
            grid-template:
                'visual visual'
                'box text'
                'input input'
                / auto 1fr;
        }

        .mdc-label {
            display: contents;
        }
        .ca-checkbox-visual-container {
            @include tile-image-container();
            grid-area: visual;
        }
        .mdc-checkbox {
            grid-area: box;
        }
        .ca-checkbox-label-text {
            grid-area: text;
            align-self: stretch;
            display: flex;
            align-items: center;
        }
        .ca-checkbox {
            margin-left: 0;
        }

        .ca-optional-input {
            grid-area: input;
            width: calc(100% - 16px);
            margin: 0 auto;
        }
    }
}

.ca-ventilation-and-heating {
    .mat-mdc-checkbox:nth-child(3),
    .mat-mdc-checkbox:nth-child(5) {
        margin-left: -2px;
    }
}

.ca-street-name-available {
    margin-top: -8px;
}
