@use '@angular/material' as mat;

@mixin ca-accordion-theme($theme) {
    .ca-accordion {
        --mat-expansion-container-shape: 4px;
        &.mat-expansion-panel {
            box-shadow: none !important;
        }

        .mat-expansion-panel-header,
        .mat-expansion-panel-header:hover {
            background-color: mat.get-theme-color($theme, primary, 100) !important;
        }

        .mat-expansion-indicator {
            svg {
                width: 32px;
                height: 32px;
            }
        }
        .mat-expansion-panel-header-title {
            font-size: 20px;
        }

        .ca-create-report-accordion {
            .mat-expansion-panel-header[aria-disabled='true'] {
                color: map-get($grey-palette, 10) !important;
            }
        }
    }
}
