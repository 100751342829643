@mixin ca-slider-theme($theme) {
    .ca-slider {
        .mat-ripple {
            display: none;
        }

        .mdc-slider__thumb-knob {
            border-width: 2px !important;
            border-color: mat.get-theme-color($theme, primary, 50) !important;
            background-color: mat.get-theme-color($theme, primary, 100) !important;
        }
    }
}

.ca-living-area-slider {
    .mat-mdc-slider {
        width: 66%;
    }
}
