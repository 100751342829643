@use '@angular/material' as mat;

@mixin ca-tooltip-theme($theme) {
    .ca-tooltip {
        position: relative;
        overflow: visible !important;
        &:after {
            content: '';
            position: absolute;
            left: calc(50% - 8px);
            bottom: -16px;
            border: 8px solid transparent;
            border-top-color: mat.get-theme-color($theme, primary, 100);
        }
        .mdc-tooltip__surface {
            color: mat.get-theme-color($theme, primary, 10) !important;
            background-color: mat.get-theme-color($theme, primary, 100) !important;
            padding: 8px;
            max-width: 320px;
            line-height: 18px !important;
            box-shadow: 0 4px 8px 0 rgba(17, 17, 17, 0.08), 0 8px 16px 0 rgba(17, 17, 17, 0.08), 0 2px 4px 0 rgba(17, 17, 17, 0.08);
        }
        &.ca-tooltip-long {
            .mdc-tooltip__surface {
                max-width: none;
            }
        }
    }
}
